.line{
    display: flex;
    justify-content: space-between;
    input{
        // max-width: 90%;
    }
    select{
        // max-width: 90%;
    }
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    background-color: white;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}
input{
    width: calc(100% - 50px);
    height: 30px;
    font-size: 20px;
    // padding-left: 2%;
    // padding-right: 20%;
    border-radius: 4px;
    padding-right: 40px;
    padding-left: 10px;
}
input::placeholder{
    // padding: 10px;
}
select{
    width: 100%;
    height: 35px;
    font-size: 20px;
    // padding-left: 2%;
    // padding-right: 20%;
    border-radius: 4px;
    margin-right: 30px;
}

form{
    width: 100%;
}

.form{
    width: 400px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 40px;
}

button{
    width: 100%;
    color: white;
    font-size: 25px;
    font-weight: bold;
    height: auto;
    margin-top: 20px;

}
.RegisterPage{
    background-image: url('../../../source/Images/menus/book-shelf-header@2x.jpg');
}