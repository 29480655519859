.bg{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    position: relative;
}


.tests{
    position: relative;
    background-image: url(../../source/Images/station/station-background@2x.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    width: 1000px;
    height: 693px;
    margin: auto;
    
    .trackUnUsed{
        height: 54px;
        margin-top: 72px;
    }
    .trackUsed{
        position: absolute;
        bottom: 79px;
        height: 101px;
        width: 100%;
    }
    .response{
        position: absolute;
        z-index: 10;
        right: 0;
        bottom: 333px;
    }

    .platform{
        position: absolute;
        width: 897px;
        height: 83px;
        margin-left: 104px;
        bottom: 311px;
    }
    .train{
        position: absolute;
        z-index: 12;
        left: 100vw;
    }
    .answers{
        position: absolute;
        bottom: 17vh;
        left: 27vw;
        height: 11vw;
        z-index: 10;
        background-color: red;
    }
    .nextTest{
        position: absolute;
        bottom: 0;
        width: 1000px;
        text-align: center;
        .text {
            position: absolute;
            color: white;
            font-size: 25px;
            font-weight: bold;
            bottom: -3px;
            right: 482px;
            text-align: center;
            -webkit-animation: text 1s ease-in-out infinite alternate;
            -moz-animation: text 1s ease-in-out infinite alternate;
            animation: text 1s ease-in-out infinite alternate;
          }
          
         
        // .text{
        //     position: absolute;
        //     color: white;
        //     font-size: 25px;
        //     font-weight: bold;
        //     bottom: -3px;
        //     right: 482px;
        // }
        img{
            width: 246px;
            height: 127px;
            margin: 0 auto -5px;
        }
    }
    .signal{
        width: 45px;
        position: absolute;
        left: 38px;
        bottom: 215px;
        img{
            width: 100%;
        }
    }
    .sign{
        position: absolute;
        bottom: 0;
        margin-bottom: -5px;
        margin-left: 20px;
        width: 200px;
        height: 77px;
        img{
            width: 100%;
            height: 100%;
        }
        p{
            position: absolute;
            z-index: 10;
            top: 0.75vh;
            font-size: 31px;
            color: white;
            width: 100%;
            text-align: center;
        }
    }
    .checkMark{
        position: absolute;
        bottom: 0;
        right: 0;
        img{
            width: 179px;
            height: 77px;
            margin-bottom: -5px;
        }
    }

    .mark{
        left: 5%;
        width: 90%;
        height: 6.58vh;
        margin-top: 6%;
        top: 0;
        position: absolute;
        img{
            width: 32px;
            height: 33px;
        }
    }
}

@keyframes text {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
    }
    to {
      text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
    }
  }