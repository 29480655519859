.rtShema{
    text-align: center;
    border-radius: 50%;
    border: 8px solid purple ;
    height: 150px;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-self: center;
    justify-content: center ;
    margin-bottom: 10px;
    background-color: white;
    img{
        align-self: center;
        // width: 50%;
        max-height: 40%;
    }
    .text{
        font-size: 16px;
    }
}