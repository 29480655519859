@-webkit-keyframes shake {
    0% { -webkit-transform: translate(2px, 1px) rotate(0deg); } 
    10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); }
    20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); }
    30% { -webkit-transform: translate(0px, 2px) rotate(0deg); }
    40% { -webkit-transform: translate(1px, -1px) rotate(1deg); }
    50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); }
    60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); }
    70% { -webkit-transform: translate(2px, 1px) rotate(-1deg); }
    80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); }
    90% { -webkit-transform: translate(2px, 2px) rotate(0deg); }
    100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); }
}
.shake:hover {
    -webkit-animation-name: shake;
    -webkit-animation-duration: 0.5s;
    -webkit-transform-origin:50% 50%;
    -webkit-animation-iteration-count: infinite;
}
.shake {
    display:inline-block
}


.Train{
    position: absolute;
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    height: fit-content;
    width: 92vw;
    bottom: -289px;
    .traineEngine{
        position: relative;
        height: fit-content; 
        // margin-top: 1vh;
    }
  .promt{
    position: absolute;
    height: 42%;
    left: 38%;
    top: -26%;
    width: 31%;
    .promtImg{
        width: 100%;
        position: absolute;
    }
    .promtListenIcon{
        position: absolute;
        z-index: 5;
        left: 10%;
        top: 17%;
        width: 76%;
    }
}  

.engine{
    padding-top: 12%;
    width: 100%;
}

.whell{
    position: absolute;
    bottom: -4%;
    left: 20%;
    .small{
        width:   23%;
    }
    .large{
        width: 27%;
    }
}
.traineCar{
    display: flex;
    height: fit-content;
}
}

