div:focus{
    outline: none;
}

.img{
    position: absolute;
}

.bg{
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
}

.rtRender{
    height: 693px;
    width: 1000px;
    margin: auto;
    // height: 100vh;
    // background-color: blue;
    background-size: 100% 100%;
}

.carousel{
    display: flex;
    justify-content: center;
    // div{
    //     width: 50%;
    //     height: 50%;
    // }

    
}