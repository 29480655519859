


.rtExclusiveWord{
    position: absolute;
    top: 0;
    width: 1000px;
    height: 693px;
    overflow: hidden;
    left: 0;
    background-color: rgba(50, 50, 50, 0.6);
    margin: auto;
    z-index: 1;
    .mainSide{
        height: auto;
        max-width: 850px;
        background-color: white;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
        padding: 30px;
        opacity: 1;
        z-index: 2;
        .title{
            width: 100%;
            height: 20%;
            display: flex;

            img{
                width: 20%;
                max-width: 60px;
                max-height: 60px;
                height: auto;
                margin-right: 10px;
            }

            .Titletext{
                font-size: 22px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        .text{
            text-align: left;
            font-size: 30px;
            font-weight: 400;
        }
    }

}