
.bg{
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    background-color: tar;
    position: relative;
    // background-image: url('../../../source/patterns/exclusive-paper.png');
}

.ListenignUp{
    position: relative;
    margin: auto;
    width: 1000px;
    height: 693px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    // background-color: red;
    background-image: url('../../../source/patterns/exclusive-paper.png');
    // background: transparent;
    display: grid;
    img{
        max-height: 100%;
        max-width: 100%;
    }
}
.askUser{
    width: 100%;
    height: 100vh;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.ListenignDown
{
    width: 900px;
    height: 630px;
    display: grid;
    margin: auto;
}


.text{
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}