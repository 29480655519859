select:invalid {
    color: gray;
}
select{
    color: black;
}

.input{
    margin-top: 15px;
}

.title{
    .text
    {
        font-size: 20px;
    }
    margin-bottom: 5px;
}