.hide{
    display: none;
}


.rtBookInfo{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: rgba(50, 50, 50, 0.6);
    z-index: 7;
    .rtInfoCard{
        height: auto;
        max-width: 850px;
        background-color: white;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
        padding: 30px;
        opacity: 1;
        z-index: 2;
        .title{
            img{
                height: 36px;
                width: 36px;
                margin-right: 10px;
            }
            display: flex;
            font-weight: bold;
            font-size: 24px;
        }
        .text{
            font-size: 4.2vw;
        }
    }
}
