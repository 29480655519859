.present
{
    width: 500px;
    position: absolute;
    display: flex;
    bottom: 361px;
    z-index: 5;
    height: 145px;
    justify-content: center;
    margin-left: 382px;
    .coin{
        width: 45%;

        img{
            margin-top: 27px;
            width: 50%;
        }
    }

    .book{
        width: 45%;
        height: 100%;
        display: flex;
        .leftSide{
            width: 10%;
            height: 100%;
            background-image: url('../../../../source/Images/patterns/book-binding@2x.png');
        }
        .mainSide
        {
            width: 80%;
            height: 100%;
            .main{
                width: 80%;
                height: 80%;
                background-color: white;
                margin: 10%;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                .text{
                    width: 100%;
                    text-align: center;
                    font-size: 10px;
                    height: 20%;
                    justify-content: center;
                    flex-direction: column;
                    padding-top: 4%;
                }
                .img{
                    width: 100%;
                    height: 70%;
                    display: flex;
                    justify-content: center;
                    img{
                        width: 80%;
                    }
                }
            }
            // background-color: white;
        }
    }
}