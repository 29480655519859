.Shop{
    position: relative;
    width: 175px;
    margin-left: 97px;
    margin-top: 12px;
    height: 161px;
    img{
        width: 100%;
        height: 100%;
    }

    .dropInfo{
         width: 85px;
    height: 85px;
    position: absolute;
    margin: auto;
    top: 124px;
    left: 22px;
    z-index: 1;
    opacity: 0.8;
    }
   
    .sign{
        width: 100%;
        height: 50px;
        position: relative;
        .signText{
            position: absolute;
            z-index: 2;
            width: 100%;
            text-align: center;
            font-size: 30px;
            font-weight: bold;
            color: #933C21;
        }

        .signImg{
            position: absolute;
        }
    }

    .dog{
        position: absolute;
        width: 57%;
        height: 52%;
        top: 47%;
        right: 19%;
    }
    .leftHand{
        right: 49%;
        top: 87%;
        position: absolute;
        width: 21%;
        height: 12%;
    }
    .rightHand{
        right: 23%;
        top: 87%;
        position: absolute;
        width: 21%;
        height: 12%;
    }
}