.text{
    display: flex;
    // justify-content: ;
}
.textBlock{
    margin-right: 10px;
}
p{
    margin: 0;
}
.bg{
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    background-color: white;
}
.rtBooksPage{
    overflow: auto;
    // 2732 x 2048 
    position: relative;
    margin: auto;
    width: 1000px;
    height: 693px;
    // width: 2732px;
    // height: 2048px;
    display: flex;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    background-image: url('../BooksPreview/img/buttonBg.jpg');
}
.break {
    flex-basis: 100%;
    height: 0;
  }
.hide{
    display: none;
}
.content{
    width: 80%;
    padding-top: 10vh;
    overflow: hidden;
    
    .title{
        font-size: 50px;
        font-weight: 500;
    }
    .author{
        font-size: 30px;
    }

    img{
        max-width: 80%;
        max-height: 60%;
        width: auto;
        height: auto;
        margin-top: 10px;
    }
    .text{
        justify-content: center;
        width: 130%;
        // margin-top: 10vh;
        margin-right: 15px;
        display: flex;
        flex-wrap: wrap;
        font-size: 35px;
        margin-top: 15px;
        margin-left: -76px;
    }
}
.page{
    width: 80%;
    height: 114%;
    margin: auto;
    img{
        max-width: 80%;
        max-height: 50%;
        height: auto;
        width: auto;
    }
}
.prev{
    overflow: hidden;
    width: 10%;
    transform: rotate(180deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    img{
        width: 100%;
    }
}
.next{
    overflow: hidden;
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img{
        width: 100%;
    }
}

