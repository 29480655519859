.header{
    height: 60px;
    display: flex;
    justify-content: space-between;
    // width: 100%;
    width: 960px;
    margin: auto;
    padding: 0 20px;
    margin-bottom: 10px;
    .logo{
        height: 100%;
    }
    img{
        width: auto;
        height: 100%;
    }
    a{
        position: unset;
        display: initial;
    }
    button{
        width: auto;
        margin-top: 20px;
        margin: 20px auto;
        border: solid 5px green;
        border-radius: 5px;
        // font-size: 40px;
        color: white;
        background-color: green;
        margin-left: 10px;
    }
}