.TraineCar{
    position: relative;
    height: fit-content;    
    // width: 18%;
    width: 100%;
    height: 100%;
    margin-top: 1%;
    .traineCar{
        width: 100%;
    }
    .wheel{
        position: absolute;
        width: 80%;
        right: 10%;
        left: 10%;
        bottom: -4%;
        // display: flex;
        // justify-content: space-between;
        .secondWhell{
            position: absolute;
            right: 0;
        }
        img{
            width: 42%;
        }
    }
}