.AskUser{
    display: none;
    width: 1000px;
    height: 693px;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 10;
    background-color: rgba(0.5, 0.5, 0.5, 0.7);
    .mainSide{
        margin: auto;
    }
    button{
        width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 5px solid grey;
    font-size: 24px;
    font-weight: bold;
    background-color: white;
    margin-right: 30px;
    }
}