.Traks{
    background-image: url(../../../../source/Images/trains/tracks@2x.png);
    background-repeat: repeat-x;
    background-size: auto 100%;
    height: 100%;
}

.trakImg{
    
}

.Traks::before {
    transform: rotate(30deg);
  }