.circle{
    top: 12px;
    left: 14px;
    position: absolute;
    z-index: 2;
}

.planck{
    position: absolute;
    left: 0;
    top: 46px;
}

.back{
    top: 32px;
    position: absolute;
    left: 30px;
    z-index: 3;
}

.BackArrow{
    position: absolute;
    width: 100px;
    height: 100px;
}

