.glow {
    background-color: 	rgb(255,255,0);
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
  }
  
  @-webkit-keyframes glow {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
    }
    to {
      text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
    }
  }

  .App-pages {
    height: 100%;
    width: 100%;
    perspective: 960px;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
 
.App-page {
    display: flex;
    justify-content: center;
    height: 100%;
}
 
.App-page_red {
    background: #f44336;
}
 
.App-page_green {
    background: #4caf50;
}
 
.App-page_blue {
    background: #2196f3;
}
 
.App-page_orange {
    background: #ff9800;
}