.active{
    opacity: 1;
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin: auto;
    vertical-align: middle;
    width: 400px;
    height: 250px;
    z-index: 4;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    
}

.active img{
    max-height: 160px;
}
.halfVisibler{
    font-size: 20px;
    display: flex;
    position: absolute;
    margin: auto;
    top: 0; left: 570px; bottom: 0; right: 0;
    opacity: 0.8;
    transform: rotate3d(0, 1, 0, 45deg); 
    width: 300px;
    height: 160px;
    z-index: 3;
}

.halfVisibler img{
    max-height: 65px;
}
.halfVisiblel img{
    max-height: 65px;
}
.quartVisibler{
    font-size: 10px;
    display: flex;
    position: absolute;
    margin: auto;
    top: 0; left: 860px; bottom: 0; right: 0;
    opacity: 0.4;
    transform: rotate3d(0, 1, 0, 60deg); 
    width: 250px;
    height: 100px;
    z-index: 2;
}

.quartVisibler img{
    max-height: 50px;
}
.quartVisiblel img{
    max-height: 50px;
}

.halfVisiblel{
    font-size: 20px;
    display: flex;
    position: absolute;
    margin: auto;
    top: 0; left: -570px; bottom: 0; right: 0;
    opacity: 0.8;
    transform: rotate3d(0, 1, 0, -45deg); 
    width: 300px;
    height: 160px;
    z-index: 3;
}

.quartVisiblel{
    font-size: 10px;
    display: flex;
    position: absolute;
    margin: auto;
    top: 0; left: -860px; bottom: 0; right: 0;
    opacity: 0.4;
    transform: rotate3d(0, 1, 0, -60deg); 
    width: 250px;
    height: 100px;
    z-index: 2;
}


.dontVisible{
   display: none;
   z-index: 1;
}