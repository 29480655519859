.bg{
    display: flex;
    height: 100vh;
    width: 100vw;
    top: 0;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    position: absolute;
    background: none;
}

.Notification{
    position: relative;
    top: 0;
    overflow: hidden;
    width: 1000px;
    height: 693px;
    margin: 0 auto;
    background-color: rgba(0.5,0.5,0.5,0.7);
    .alert{
        width: 40%;
        height: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        .alertSign{
            margin-top: 22%;
            height: 58%;
            width: 99%;
            margin-left: 11%;
        }
        .alertPost{
            height: 30%;
            width: 10%;
            left: 40%;
            margin-left: 55.5%;
        }

        .alertText{
            position: absolute;
            font-size: 32px;
            margin-top: 60%;
            width: 62%;
            margin-left: 30%;
            font-weight: bold;
        }
       
    }

    .button{
        position: absolute;
        width: 40%;
        margin-right: 5%;
        right: 0;
        display: flex;
        flex-direction: column; 
        
        .alert_light{
            height: 11%;
            width: 43%;
            margin-left: 39%;
            margin-top: 23%;
        }

        .alertPostLight{
            height: 8%;
            width: 10%;
            left: 40%;
            margin-left: 55.5%;
        }

        

        .greenLight{
            width: 100%;
            // margin-left: 46%;
            // margin-top: 36%;
            position: absolute;
        }

        .redLight{
            position: absolute;
            width: 100%;
            // margin-left: 46%;
            // margin-top: 76%;
        }

        .okay{
            margin-left: 28%;
            margin-top: 28%;
            font-weight: bold;
            color: white;
            font-size: 34px;
            position: absolute;
        }

        .no{
            margin-left: 28%;
            margin-top: 28%;
            font-weight: bold;
            color: white;
            font-size: 34px;
            position: absolute;
        }

        .okBlock{
            position: absolute;
            width: 29%;
            margin-left: 46%;
            margin-top: 36%; 
        }
        
        .noBlock{
            position: absolute;
            width: 29%;
            margin-left: 46%;
            margin-top: 77%; 
        }
    }

    
}