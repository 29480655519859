.loginPage{
    width: 400px;
    margin: auto;
    margin-top: 40px;
}

.unit{
    display: grid;
    grid-template-columns: repeat(auto-fill, 310px);
    justify-content: center;
}


.statBlock{
    justify-content: center;
    display: flex;
    color: white;
    font-size: 30px;
    margin-bottom: 28px;
}

.bg{
    
    background-image: url('../../../../../source/Images/menus/book-shelf-2208@3x.png');
    // background-image: url('../../source/Images/menus/book-shelf-1334.png');
}