.bg{
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    background-color: white;
}

.rtBooksPreview{
    position: relative;
    margin: auto;
    width: 1000px;
    height: 693px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .info{
        width: 15%;
        height: 693px;
        background-image: (url('../../../source/patterns/book-binding@2x.png'));
        background-size: 100%;
        img{
            padding: 20px;
            /* background-color: white; */
            position: absolute;
            bottom: 78px;
            left: 20px;
            height: 60px;
            width: 60px;
        }
        .coinInfo{
            padding: 20px;
            position: absolute;
            bottom: 6px;
            left: 43px;
            height: 60px;
            width: 60px;
            font-size: 20px;
            color: white;
        }
    }

    .bookIntro{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        border-radius: 30px;
        border-color: brown;
        border-style: solid;
        margin: 3% 20% 0 21%;
        width: 721px;
        // max-width: 68vw;
        padding-bottom: 131px;
        height: 465px;
        background-color: white;
        .title{
            text-align: center;
            font-size: 60px;
            font-weight: bold;
        }
        img{
            // padding: 0 10%;
            margin: 0 auto;
            max-height: 70%;
            max-width: 90%;
            height: auto;
            width: auto;
        }
    }
    .bookMode{
        position: relative;
        bottom: 24vh;
        margin-left: 31%;
        width: 52%;
        display: flex;
        justify-content: space-between;
        
    }
}