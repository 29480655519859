.userType{
    text-align: center;
    .block{
        display: flex;
        justify-content: center;
        // min-width: 1000px;
        text-align: center;
        margin: auto;
    }
    .title{
        font-size:40px;
    }

    .userTypeBlock{
        display: flex;
        flex-direction: column;
        margin-right: 24px;
        width: 290px;
        img{
            margin: 10px auto;
            width: 174px;
        }
    }
}