.rtLevelRender{
    width: 266px;
    height: 216px;
    display: flex;
    justify-content: center;
    margin: 30px;

    .leftSide{
        width: 20%;
        background-image: url('../../../../source/Images/patterns/book-binding-small@2x.png');
    }
    .mainSide{
        width: 80%;
    }
    .imgBox{
        // display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 82%;
        width: 82%;
        text-align: center;
        margin: auto;
        font-size: 140%;
        margin-top: 8.5%;
        border-radius: 20px;
        font-weight: 400;
        background-color: white;
        // justify-content: center;
        padding: 3%;
        display: grid;
        grid-template-rows: min-content;
    }
    img{
        // height: 75%;
        // width: auto;
        max-width: 100%;
        margin: auto;
        max-height: 100%;
    }
}