.response{
    display: flex;
    justify-content: space-between;
    // width: 50%;
    margin: auto;
    margin-right: 3vw;
}

.block{
    position: relative;
    display: block;
    height: 87px;
    width: 85px;
    margin-right: 10px;
}

.answer2{
    border: 10px solid black;
    width: 400px;
    margin-top: 200px;
    height: 400px;
}