.block{
    background-image: url(../../../../source/trains/crate-large.png);
    height: 7vw;
    width: 7vw;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .text{
        font-size: 1.3vw;
        display: flex;
        flex-direction: column;
        background-color: transparent;
        width: 100%;
        height: 100%;
        text-align: center;
        vertical-align: middle;
        justify-content: center;
    }
    .full{
        display: flex;
        flex-direction: column;
    }
    .img{
        // padding: 5%;
        img{
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-self: center;
            margin: auto;
            padding-top: 14%;
            width: 72%;
            height: 100%;
        }
    }
}