.rtModeButton{
    border-radius: 50%;
    background-image: url(/static/media/buttonBg.fd4aecc7.jpg);
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 30px;
    border: 4px solid grey;
    position: relative;
    img{
        width: 70px;
        height: 70px;
        margin: 0 auto;
    }
    
    .price{
        position: absolute;
        top: -16%;
        right: 36%;
    }

    .priceImg{
        position: absolute;
    }

    .priceText{
        position: absolute;
        left: 27px;
        top: 10px;
    }
}