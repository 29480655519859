.bg{
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    background-color: white;
}

.rtFinalPage{
    margin: auto;
    display: flex;
    padding: 10px;
    position: relative;
    width: 1000px;
    height: 693px;
}


.title{
    height: 20vh;
    display: flex;
    justify-content: center;
    
    .titleText{
        margin-top: 50px;
        padding-bottom: auto;
        background-color: white;
        align-items: center;
        width: 300px;
        height: 50px;
        line-height: 35px;
        border: 5px solid purple;
        text-align: center;
        vertical-align: middle;
        font-weight: bold;
        font-size: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.schema{
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
    justify-content: center;
}

.info{
    position: absolute;
    width: 70px;
    height: 70px;
    bottom: 0;
    left: 0;
    background-color: white;
    z-index: 5;
    img{
        width: 100%;
        height: 100%;
    }
}

.main{
    width: 90%;
}
.prev{
    overflow: hidden;
    width: 5%;
    transform: rotate(180deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    img{
        width: 100%;
    }
}
.next{
    overflow: hidden;
    width: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img{
        width: 100%;
    }
}