.bg{
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    background-color: white;
}

.rtBooksPreview{
    // width: 100vw;
    position: relative;
    margin: auto;
    width: 1000px;
    height: 693px;
    // height: 100vh;
    // overflow: hidden;
    // background-image: url('../../../../source/patterns/pattern-8.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .info{
        // background-attachment: fixed;
        width: 15%;
        // height: 100vh;
        height: 693px;
        background-image: (url('../../../../source/patterns/book-binding@2x.png'));
        background-size: 100%;
        img{
            padding: 20px;
            background-color: white;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 40px;
            width: 40px;
        }
    }

    .bookIntro{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        border-radius: 30px;
        border-color: brown;
        border-style: solid;
        margin: 3% 20% 0 21%;
        width: 721px;
        // max-width: 68vw;
        height: 596px;
        background-color: white;
        .title{
            text-align: center;
            font-size: 60px;
            font-weight: bold;
        }
        img{
            // padding: 0 10%;
            margin: 0 auto;
            // max-height: 60%;
            // max-width: 60%;
            height: auto;
            width: auto;
        }
    }
    .bookMode{
        position: relative;
        bottom: 24vh;
        margin-left: 25%;
        width: 65%;
        display: flex;
        justify-content: space-between;
        
    }
}