.rtChosseLevel{
    // width: 100vw;
    display: flex;
    justify-content: space-between;
    background-image: url('../../../../source/Images/menus/book-shelf-header@2x.jpg');
    overflow: hidden;
    .active{
        border: solid 5px whitesmoke;
    } 
    .disable{
        border: solid 5px green;
    }
    button{
        width: auto;
        margin-top: 20px;
        margin: 20px auto;
        border: solid 5px whitesmoke;
        border-radius: 5px;
        font-size: 40px;
        color: white;
        background-color: green;
    }

}