.Quid{
    background-image: url(../../../../../../source/patterns/lesson-menu.png);
    text-align: center;
    position: absolute;
    top: 0;
    right: 20px;
    height: 100px;
    width: 100px;
    z-index: 100;
   background-repeat: no-repeat;
   background-size: 100% 100%;
    .quidOpen{
        text-align: center;
         display: flex;
        flex-direction: column;
    }
    .close{
        margin: auto;
        margin-top: 25%;
        height: 40%;
        width: 70%;
    }

    .mode{
        margin: auto;
        height: 35%;
        margin-top: 5%;
        width: 35%;
    }

    .quit{
        margin: auto;
        height: 55%;
        margin-top: 15%;
        width: 55%;
    }
    .triangle{
        margin: auto;
        margin-top: 15%;
        transform: rotate(180deg);
        height: 30%;
        width: 30%;
    }
}