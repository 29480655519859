.makeSmall{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
}

.rtLevel{
    width: 1000px;
    height: 693px;
    overflow: auto;
    margin: auto;
}
.unit{
    display: grid;
    grid-template-columns: repeat(auto-fill, 310px);
    justify-content: center;
}
.bg{
    
    background-image: url('../../source/Images/menus/book-shelf-2208@3x.png');
    // background-image: url('../../source/Images/menus/book-shelf-1334.png');
}