.rtRenderBook{
    height: 500px;
    width: 500px;
    -webkit-box-reflect: below 0px -webkit-linear-gradient(bottom, rgba(255, 255, 255, 0.3) 0%, transparent 50%, transparent 100%);
    margin-bottom: 10px;
    .leftSide{
        background-image: url('../../../../../source/patterns/book-binding-small@2x.png');
        width: 15%;
        background-repeat: repeat-y;
        background-size: 100% auto;
        height: 100%;
    }


    .mainSide{
        width: 70%;
        // background-image: url('../../../../../source/patterns/pattern-'+random(25)+'.png');
        display: flex;
        flex-direction: column;
        justify-content: center;
        .mainInfo{
            text-align: center;
            height: 80%;
            width: 90%;
            margin: auto;
            background-color: white;
            border-radius: 10px;
            display: grid;
            grid-template-rows: min-content;
            .title{
                margin-top: 5%;
                // font-size:  1.8vw;
                font-weight: bold;
            }

            img{
                bottom: 20px;
                margin: auto;
                max-height: inherit;
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                // border-radius: 0 0 10px 10px;
            }
        }
    }
}